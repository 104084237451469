import { environment } from '@environments/environment.development';

export const apiRoutes = {
  AUTHORIZATION_BEARER_TOKEN_API: `${environment.aladdinOnDemandUrl}/authorizationserver/oauth/token`,
  REGISTER_USER_URL: `${environment.aladdinOnDemandUrl}/services/v2/aladdin/users`,
  FORGOT_PASSWORD_URL: `${environment.aladdinOnDemandUrl}/services/v2/aladdin/forgottenpasswordtokens`,
  RESET_PASSWORD_URL: `${environment.aladdinOnDemandUrl}/services/v2/aladdin/resetpassword`,
  VALIDATE_OTP_URL: `${environment.aladdinOnDemandUrl}/services/v2/aladdin/validateotp`,
  USER_PROFILE_URL: `${environment.aladdinOnDemandUrl}/services/v2/aladdin/users`
};
